import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnInit,
  Renderer2,
  RendererStyleFlags2,
  booleanAttribute,
  effect,
  inject,
  input,
} from '@angular/core';

@Component({
  // We use an attribute selector here instead of the tag, so that developers using this component can directly access all the features of the native tag.
  selector: 'button[fsn-button],a[fsn-button]',
  standalone: true,
  imports: [CommonModule],
  template: `<ng-content />`,
  styleUrl: './button.component.scss',
})
export class ButtonComponent implements OnInit {
  @HostBinding('class.fs-next') @HostBinding('class.fsn-button') private readonly _cls = true;
  private readonly host = inject<ElementRef<HTMLAnchorElement | HTMLButtonElement>>(ElementRef);
  private readonly renderer = inject(Renderer2);

  appearance = input<'link' | 'button'>('button');
  color = input<'primary' | 'secondary'>('primary');
  size = input<'natural' | 'tiny' | 'small' | 'medium' | 'large'>('medium');
  disabled = input(false, { transform: booleanAttribute });

  @HostBinding('class')
  protected get cls() {
    return {
      'fsn-btn-appearance-link': this.appearance() === 'link',
      'fsn-btn-color-primary': this.appearance() === 'button' && this.color() === 'primary',
      'fsn-btn-color-secondary': this.appearance() === 'button' && this.color() === 'secondary',
      'fsn-btn-size-natural': this.size() === 'natural',
      'fsn-btn-size-tiny': this.size() === 'tiny',
      'fsn-btn-size-small': this.size() === 'small',
      'fsn-btn-size-medium': this.size() === 'medium',
      'fsn-btn-size-large': this.size() === 'large',
      'fsn-btn-disabled': this.disabled(),
    };
  }

  protected readonly onButtonDisabled = effect(() => {
    const disabled = this.disabled();
    const host = this.host.nativeElement;
    if ('disabled' in host) host.disabled = disabled;
  });

  ngOnInit() {
    const host = this.host.nativeElement;
    if (host.tagName === 'A') {
      host.addEventListener('pointerdown', event => this.doNothingWhenDisabled(event), { capture: true });
      host.addEventListener('click', event => this.doNothingWhenDisabled(event), { capture: true });
    }
  }

  protected doNothingWhenDisabled(event: Event) {
    if (this.disabled()) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener('pointerdown')
  protected calculateSize() {
    const host = this.host.nativeElement;
    this.renderer.setStyle(host, '--fsn-btn-calculated-width', host.offsetWidth, RendererStyleFlags2.DashCase);
    this.renderer.setStyle(host, '--fsn-btn-calculated-height', host.offsetHeight, RendererStyleFlags2.DashCase);
  }
}
