import { animate, query, style, transition, trigger } from '@angular/animations';
import { Component, booleanAttribute, input } from '@angular/core';

@Component({
  selector: 'fsn-spinner',
  standalone: true,
  template: `<!---->
    <span class="cdk-visually-hidden">Loading</span>
    <img aria-hidden="true" src="/assets/images/spinner.gif" />
    <!----> `,
  styles: `
    :host {
      display: block;
      transition: all var(--fsn-anim-tf-chill-soft);
    }
    img {
      filter: brightness(1.4);
      mix-blend-mode: multiply;
      width: 64px;
    }
  `,
  host: {
    '[role]': '"progressbar"',
    '[attr.aria-live]': '"polite"',
    '[@showHide]': 'true',
    '[@.disabled]': 'noTransition()',
  },
  animations: [
    trigger('showHide', [
      transition(':enter', [
        query('img', style({ opacity: 0.01 })),
        query('img', animate('2s 0.5s', style({ opacity: 1 }))),
      ]),
      // prettier-ignore
      transition(':leave', [
        query('img', style({ opacity: 1 })),
        query('img', animate('0.5s', style({ opacity: 0 }))),
      ]),
    ]),
  ],
})
export class SpinnerComponent {
  readonly noTransition = input(false, { transform: booleanAttribute });
}
